.special-course-iu-ru-v2__link {
    position: relative;
    display: block;
    overflow: hidden;
    padding: 10px 10px 15px;
}

.special-course-iu-ru-v2__text {
    margin-bottom: 23px;
    max-width: 224px;
    font-weight: 600;
    font-size: 20px;
    line-height: 130%;
    color: #76767a;
}

.special-course-iu-ru-v2__text span {
    font-weight: 700;
    color: #181818;
}

.special-course-iu-ru-v2__text a {
    text-decoration: underline;
    color: #1489ae;
}

.special-course-iu-ru-v2__wrap {
    position: relative;
    display: flex;
    margin-bottom: 3px;
}

.special-course-iu-ru-v2__wrap::before {
    content: "";
    position: absolute;
    right: 0;
    top: -40px;
    z-index: -1;
    width: 75px;
    height: 42px;
    background: url("#{$path-img}/special-box-surprise.svg") no-repeat;
}

.special-course-iu-ru-v2__promo {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    margin-right: 10px;
    border: 1px solid #000;
    border-radius: 4px;
    padding: 3px 17px;
    width: 122px;
    height: 42px;
    font-weight: 600;
    font-style: italic;
    font-size: 24px;
    line-height: 140%;
    letter-spacing: 0.05em;
    color: #181818;
    background: #ececec;
    cursor: pointer;
}

.special-course-iu-ru-v2__copied {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    margin-right: 10px;
    border: 1px solid #000;
    border-radius: 4px;
    padding: 12px 17px;
    width: 122px;
    height: 42px;
    font-weight: 600;
    font-style: italic;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.1px;
    color: #181818;
    background: #ececec;
    visibility: hidden;
    cursor: pointer;
}

.special-course-iu-ru-v2__copied--active {
    visibility: visible;
}

.special-course-iu-ru-v2__descr {
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    color: #76767a;
}

.special-course-iu-ru-v2__activated {
    margin-left: auto;
    border-radius: 5px;
    padding: 13px;
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    color: #fff;
    background: #57a216;
}

.special-course-iu-ru-v2__activated:hover {
    color: #fff;
}

.special-course-iu-ru-v2__descr span {
    color: #181818;
}
