.special-course-box-v2__link {
  position: relative;
  display: block;
  overflow: hidden;
  padding: 20px 20px 25px;
  width: 278px;
  height: 208px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s; }

.special-course-box-v2__circle {
  content: "";
  position: absolute;
  left: -80px;
  bottom: -80px;
  z-index: -1;
  border-radius: 50%;
  width: 160px;
  height: 160px;
  background: #f60;
  -webkit-transition: all 0.4s;
  transition: all 0.4s; }

.special-course-box:hover .special-course-box-v2__circle {
  left: -41px;
  bottom: -74px;
  width: 360px;
  height: 360px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s; }

.special-course-box:hover .special-course-box-v2__text,
.special-course-box:hover .special-course-box-v2__descr {
  display: none; }

.special-course-box:hover .special-course-box-v2__img,
.special-course-box:hover .special-course-box-v2__spec {
  display: block; }

.special-course-box-v2__img {
  display: none;
  margin: 0 auto 5px; }

.special-course-box-v2__spec {
  display: none;
  font-weight: 700;
  font-size: 16px;
  line-height: 125%;
  text-align: center;
  color: #fff; }

.special-course-box-v2__text {
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 18px;
  line-height: 130%;
  color: #181818; }

.special-course-box-v2__text span {
  color: #e44d56; }

.special-course-box-v2__descr {
  margin-bottom: 25px;
  font-weight: 400;
  font-size: 14px;
  line-height: 125%;
  color: #181818; }

.special-course-box-v2__btn {
  position: absolute;
  bottom: 15px;
  display: inline-block;
  border-radius: 4px;
  padding: 19px 26px 17px;
  width: 238px;
  -webkit-box-shadow: 0 0 30px rgba(255, 222, 0, 0.35), inset 0 -2px 2px rgba(0, 0, 0, 0.25);
          box-shadow: 0 0 30px rgba(255, 222, 0, 0.35), inset 0 -2px 2px rgba(0, 0, 0, 0.25);
  font-weight: 600;
  font-size: 14px;
  line-height: 123%;
  letter-spacing: 0.01em;
  white-space: nowrap;
  text-align: center;
  color: #000;
  background: #ffde00;
  -webkit-transition: all 0.5s;
  transition: all 0.5s; }
  .special-course-box-v2__btn:hover {
    -webkit-box-shadow: 0 0 30px #edd00d, inset 0 -2px 2px rgba(0, 0, 0, 0.25);
            box-shadow: 0 0 30px #edd00d, inset 0 -2px 2px rgba(0, 0, 0, 0.25);
    background-color: #ffde00;
    -webkit-transition: all 0.5s;
    transition: all 0.5s; }

.special-course-iu-ru-v2__link {
  position: relative;
  display: block;
  overflow: hidden;
  padding: 10px 10px 15px; }

.special-course-iu-ru-v2__text {
  margin-bottom: 23px;
  max-width: 224px;
  font-weight: 600;
  font-size: 20px;
  line-height: 130%;
  color: #76767a; }

.special-course-iu-ru-v2__text span {
  font-weight: 700;
  color: #181818; }

.special-course-iu-ru-v2__text a {
  text-decoration: underline;
  color: #1489ae; }

.special-course-iu-ru-v2__wrap {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 3px; }

.special-course-iu-ru-v2__wrap::before {
  content: "";
  position: absolute;
  right: 0;
  top: -40px;
  z-index: -1;
  width: 75px;
  height: 42px;
  background: url("../img//special-box-surprise.svg") no-repeat; }

.special-course-iu-ru-v2__promo {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  margin-right: 10px;
  border: 1px solid #000;
  border-radius: 4px;
  padding: 3px 17px;
  width: 122px;
  height: 42px;
  font-weight: 600;
  font-style: italic;
  font-size: 24px;
  line-height: 140%;
  letter-spacing: 0.05em;
  color: #181818;
  background: #ececec;
  cursor: pointer; }

.special-course-iu-ru-v2__copied {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  margin-right: 10px;
  border: 1px solid #000;
  border-radius: 4px;
  padding: 12px 17px;
  width: 122px;
  height: 42px;
  font-weight: 600;
  font-style: italic;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.1px;
  color: #181818;
  background: #ececec;
  visibility: hidden;
  cursor: pointer; }

.special-course-iu-ru-v2__copied--active {
  visibility: visible; }

.special-course-iu-ru-v2__descr {
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  color: #76767a; }

.special-course-iu-ru-v2__activated {
  margin-left: auto;
  border-radius: 5px;
  padding: 13px;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  color: #fff;
  background: #57a216; }

.special-course-iu-ru-v2__activated:hover {
  color: #fff; }

.special-course-iu-ru-v2__descr span {
  color: #181818; }

.special-course-box--v3 .special-course-box__link {
  padding: 7px 15px 15px; }

.special-course-box--v3 .special-course-box__text {
  position: relative;
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  color: #ffde00; }

.special-course-box--v3 .special-course-box__text--circle {
  padding-left: 9px; }

.special-course-box--v3 .special-course-box__text--circle::before {
  content: "";
  position: absolute;
  left: 0;
  top: 7px;
  border-radius: 50%;
  width: 5px;
  height: 5px;
  -webkit-animation-name: onlineTranslation;
          animation-name: onlineTranslation;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }

.special-course-box--v3 .special-course-box__title {
  margin: 0 0 16px;
  max-width: 368px;
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  color: #fff; }

.special-course-box--v3 .special-course-box__title-accent {
  color: #c3e5ef; }

.special-course-box--v3 .special-course-box__images {
  position: relative; }

.special-course-box--v3 .special-course-box__images-picture {
  width: 100%; }

.special-course-box--v3 .special-course-box__broadcast {
  position: absolute;
  left: 8px;
  top: 8px;
  display: inline-block;
  border-radius: 4px;
  padding: 0 5px 2px 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  color: #fff;
  background-color: #e5363d; }
  .special-course-box--v3 .special-course-box__broadcast::before {
    content: "";
    position: absolute;
    left: 5px;
    top: 50%;
    display: inline-block;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    background-color: #fff;
    -webkit-transform: translateY(-46%);
            transform: translateY(-46%); }

.special-course-box--v3 .special-course-box__play {
  position: absolute;
  left: 8px;
  top: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.special-course-box--v3 .special-course-box__play img {
  margin-right: 7px;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 20px rgba(255, 222, 0, 0.25), inset 0 -2px 1px rgba(0, 0, 0, 0.28);
          box-shadow: 0 0 20px rgba(255, 222, 0, 0.25), inset 0 -2px 1px rgba(0, 0, 0, 0.28);
  -webkit-transition: all 0.5s;
  transition: all 0.5s; }

.special-course-box--v3 .special-course-box__play img:hover {
  -webkit-box-shadow: 0 0 30px #edd00d, inset 0 -2px 2px rgba(0, 0, 0, 0.25);
          box-shadow: 0 0 30px #edd00d, inset 0 -2px 2px rgba(0, 0, 0, 0.25);
  -webkit-transition: all 0.5s;
  transition: all 0.5s; }

.special-course-box--v3 .special-course-box__play-text {
  display: inline-block;
  max-width: 93px;
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  color: #222; }

.special-course-box--v3.special-course-box {
  border: none;
  border-radius: 4px;
  max-width: 393px;
  -webkit-box-shadow: 0 5px 20px rgba(34, 34, 34, 0.2);
          box-shadow: 0 5px 20px rgba(34, 34, 34, 0.2);
  background: #36a8cb; }

@-webkit-keyframes onlineTranslation {
  from {
    background: #ffde00; }
  to {
    background: rgba(255, 222, 0, 0.5); } }

@keyframes onlineTranslation {
  from {
    background: #ffde00; }
  to {
    background: rgba(255, 222, 0, 0.5); } }

@media (max-width: 576px) {
  .special-course-box--v3 .special-course-box__title {
    font-size: 16px; }
  .special-course-box--v3 .special-course-box__play-text {
    font-size: 16px; }
  .special-course-box--v3.special-course-box {
    left: 3px;
    bottom: 60px;
    max-width: 360px; } }

@media (max-width: 370px) {
  .special-course-box--v3.special-course-box {
    max-width: 300px; } }

.special-course-box {
  position: fixed;
  left: 20px;
  bottom: 20px;
  z-index: 99;
  border: 1px solid #57a216;
  border-radius: 4px;
  width: 100%;
  max-width: 280px;
  -webkit-box-shadow: 0 0 20px rgba(34, 34, 34, 0.25);
          box-shadow: 0 0 20px rgba(34, 34, 34, 0.25);
  color: #000;
  background-color: #fff; }
  .special-course-box--big {
    max-width: 318px; }

.special-course-box__link {
  display: block;
  padding: 10px 10px 15px; }

.special-course-box__close {
  position: absolute;
  right: 10px;
  top: 5px;
  z-index: 1;
  margin: -7px -10px;
  outline: none;
  padding: 7px 10px;
  background: none; }

.special-course-box__new-course {
  margin-bottom: 12px;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  color: #ff9000; }

.special-course-box__name {
  margin-top: 16px;
  margin-bottom: 12px;
  font-weight: 700;
  font-size: 18px;
  line-height: 130%;
  color: #101010; }
  .special-course-box__name span {
    color: #ff9000; }
  .special-course-box__name--semibold {
    margin-bottom: 12px;
    font-weight: 600;
    font-size: 14px; }

.special-course-box__title {
  margin-top: 16px;
  margin-bottom: 12px;
  font-weight: 700;
  font-size: 18px;
  color: #181818; }
  .special-course-box__title span {
    color: #76767a; }

.special-course-box__bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .special-course-box__bottom p {
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    color: #f60; }
    .special-course-box__bottom p span {
      text-decoration: line-through; }
  .special-course-box__bottom .special-course-box__btn {
    margin-right: 10px; }

.special-course-box__text {
  margin-bottom: 18px;
  font-size: 14px;
  line-height: 130%;
  color: #181818; }
  .special-course-box__text--gray {
    font-weight: 600;
    color: #76767a; }
    .special-course-box__text--gray strong {
      font-weight: 700;
      color: #f60; }
  .special-course-box__text--semibold {
    margin-top: 18px;
    margin-bottom: 0;
    font-weight: 400;
    font-size: 16px; }
    .special-course-box__text--semibold b {
      font-weight: 600; }
  .special-course-box__text--discount {
    font-weight: 600;
    font-size: 18px;
    color: #76767a; }
    .special-course-box__text--discount span {
      font-weight: 700;
      color: #ff3d00; }
    .special-course-box__text--discount b {
      color: #181818; }

.special-course-box__btn {
  display: inline-block;
  margin-right: 20px;
  border-radius: 4px;
  padding: 17px 15px;
  -webkit-box-shadow: 0 0 30px rgba(255, 222, 0, 0.35), inset 0 -2px 2px rgba(0, 0, 0, 0.25);
          box-shadow: 0 0 30px rgba(255, 222, 0, 0.35), inset 0 -2px 2px rgba(0, 0, 0, 0.25);
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.01em;
  white-space: nowrap;
  color: #000;
  background: #ffde00;
  -webkit-transition: all 0.5s;
  transition: all 0.5s; }
  .special-course-box__btn:hover {
    -webkit-box-shadow: 0 0 30px #edd00d, inset 0 -2px 2px rgba(0, 0, 0, 0.25);
            box-shadow: 0 0 30px #edd00d, inset 0 -2px 2px rgba(0, 0, 0, 0.25);
    background-color: #ffde00; }
  .special-course-box__btn--full {
    display: block;
    width: 100%;
    text-align: center; }

.special-course-box__discount {
  position: absolute;
  right: -30px;
  bottom: -20px;
  z-index: 3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 120px;
  height: 120px;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  text-align: center;
  color: #ff3d00;
  background: url("../img//special-course/discount.svg") center/120px no-repeat; }
  .special-course-box__discount p {
    margin: 0 auto;
    width: 85px; }
  .special-course-box__discount span {
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    color: #181818; }

@media (max-width: 990px) {
  .special-course-box {
    bottom: 90px; } }

@media (max-width: 540px) {
  .special-course-box--big {
    left: 10px;
    max-width: 300px; } }
