.special-course-box-v2__link {
    position: relative;
    display: block;
    overflow: hidden;
    padding: 20px 20px 25px;
    width: 278px;
    height: 208px;
    transition: all 0.4s;
}

.special-course-box-v2__circle {
    content: "";
    position: absolute;
    left: -80px;
    bottom: -80px;
    z-index: -1;
    border-radius: 50%;
    width: 160px;
    height: 160px;
    background: #f60;
    transition: all 0.4s;
}

.special-course-box:hover {
    .special-course-box-v2__circle {
        left: -41px;
        bottom: -74px;
        width: 360px;
        height: 360px;
        transition: all 0.5s;
    }

    .special-course-box-v2__text,
    .special-course-box-v2__descr {
        display: none;
    }

    .special-course-box-v2__img,
    .special-course-box-v2__spec {
        display: block;
    }
}

.special-course-box-v2__img {
    display: none;
    margin: 0 auto 5px;
}

.special-course-box-v2__spec {
    display: none;
    font-weight: 700;
    font-size: 16px;
    line-height: 125%;
    text-align: center;
    color: #fff;
}

.special-course-box-v2__text {
    margin-bottom: 15px;
    font-weight: 700;
    font-size: 18px;
    line-height: 130%;
    color: #181818;
}

.special-course-box-v2__text span {
    color: #e44d56;
}

.special-course-box-v2__descr {
    margin-bottom: 25px;
    font-weight: 400;
    font-size: 14px;
    line-height: 125%;
    color: #181818;
}

.special-course-box-v2__btn {
    position: absolute;
    bottom: 15px;
    display: inline-block;
    border-radius: 4px;
    padding: 19px 26px 17px;
    width: 238px;
    box-shadow: 0 0 30px rgba(255, 222, 0, 0.35), inset 0 -2px 2px rgba(0, 0, 0, 0.25);
    font-weight: 600;
    font-size: 14px;
    line-height: 123%;
    letter-spacing: 0.01em;
    white-space: nowrap;
    text-align: center;
    color: #000;
    background: #ffde00;
    transition: all 0.5s;

    &:hover {
        box-shadow: 0 0 30px #edd00d, inset 0 -2px 2px rgba(0, 0, 0, 0.25);
        background-color: #ffde00;
        transition: all 0.5s;
    }
}