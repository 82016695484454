$path-img: "../img/";

@import "special-course-box-v2";
@import "special-course-iu-ru-v2";
@import "special-course-box-v3";

.special-course-box {
    position: fixed;
    left: 20px;
    bottom: 20px;
    z-index: 99;
    border: 1px solid #57a216;
    border-radius: 4px;
    width: 100%;
    max-width: 280px;
    box-shadow: 0 0 20px rgba(34, 34, 34, 0.25);
    color: #000;
    background-color: #fff;

    &--big {
        max-width: 318px;
    }
}

.special-course-box__link {
    display: block;
    padding: 10px 10px 15px;
}

.special-course-box__close {
    position: absolute;
    right: 10px;
    top: 5px;
    z-index: 1;
    margin: -7px -10px;
    outline: none;
    padding: 7px 10px;
    background: none;
}

.special-course-box__new-course {
    margin-bottom: 12px;
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    color: #ff9000;
}

.special-course-box__name {
    margin-top: 16px;
    margin-bottom: 12px;
    font-weight: 700;
    font-size: 18px;
    line-height: 130%;
    color: #101010;

    span {
        color: #ff9000;
    }

    &--semibold {
        margin-bottom: 12px;
        font-weight: 600;
        font-size: 14px;
    }
}

.special-course-box__title {
    margin-top: 16px;
    margin-bottom: 12px;
    font-weight: 700;
    font-size: 18px;
    color: #181818;

    span {
        color: #76767a;
    }
}

.special-course-box__bottom {
    display: flex;
    align-items: center;

    p {
        font-weight: 600;
        font-size: 14px;
        line-height: 120%;
        color: #f60;

        span {
            text-decoration: line-through;
        }
    }

    .special-course-box__btn {
        margin-right: 10px;
    }
}

.special-course-box__text {
    margin-bottom: 18px;
    font-size: 14px;
    line-height: 130%;
    color: #181818;

    &--gray {
        font-weight: 600;
        color: #76767a;

        strong {
            font-weight: 700;
            color: #f60;
        }
    }

    &--semibold {
        margin-top: 18px;
        margin-bottom: 0;
        font-weight: 400;
        font-size: 16px;

        b {
            font-weight: 600;
        }
    }

    &--discount {
        font-weight: 600;
        font-size: 18px;
        color: #76767a;

        span {
            font-weight: 700;
            color: #ff3d00;
        }

        b {
            color: #181818;
        }
    }
}

.special-course-box__btn {
    display: inline-block;
    margin-right: 20px;
    border-radius: 4px;
    padding: 17px 15px;
    box-shadow: 0 0 30px rgba(255, 222, 0, 0.35), inset 0 -2px 2px rgba(0, 0, 0, 0.25);
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.01em;
    white-space: nowrap;
    color: #000;
    background: #ffde00;
    transition: all 0.5s;

    &:hover {
        box-shadow: 0 0 30px #edd00d, inset 0 -2px 2px rgba(0, 0, 0, 0.25);
        background-color: #ffde00;
    }

    &--full {
        display: block;
        width: 100%;
        text-align: center;
    }
}

.special-course-box__discount {
    position: absolute;
    right: -30px;
    bottom: -20px;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 120px;
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    text-align: center;
    color: #ff3d00;
    background: url("#{$path-img}/special-course/discount.svg") center / 120px no-repeat;

    p {
        margin: 0 auto;
        width: 85px;
    }

    span {
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        color: #181818;
    }
}

@media (max-width: 990px) {
    .special-course-box {
        bottom: 90px;
    }
}

@media (max-width: 540px) {
    .special-course-box--big {
        left: 10px;
        max-width: 300px;
    }
}